import React, { useState, useEffect } from 'react';
import Layout from '@accrosoft-ltd/accropress-theme-alfred/src/components/layout';
import { Link } from 'gatsby';

import Modal from 'react-responsive-modal';

import 'react-responsive-modal/styles.css';

import SEO from '@accrosoft-ltd/accropress-websites-components/dist/esm/seo';

import WigLoader from '@accrosoft-ltd/accropress-websites-components/dist/esm/wigLoader';
import WeducNewsFeed from '@accrosoft-ltd/accropress-websites-components/dist/esm/weducNewsfeed';
import WeducDairyDates from '@accrosoft-ltd/accropress-websites-components/dist/esm/weducDairyDates';
import buildMediaContent from '@accrosoft-ltd/accropress-websites-components/dist/esm/buildMediaContent';

import Translate from '@accrosoft-ltd/accropress-websites-components/dist/esm/translateWidget';

import NoticesSlickSlider from '@accrosoft-ltd/accropress-theme-alfred/src/components/NoticesSlickSlider';

import '@accrosoft-ltd/accropress-theme-alfred/src/components/wig-bundle.scss';

import { returnResizedImageURL } from '@accrosoft-ltd/accropress-websites-components/dist/esm/imgUtils';

require('es6-promise').polyfill();
var moment = require('moment');

const gatsbyConfig = require('@accrosoft-ltd/accropress-theme-alfred/theme-config.js');

const gatsbyConfigSiteMetaData = gatsbyConfig.siteMetadata;
const gatsbyConfigPlugins = gatsbyConfig.plugins;
const gatsbyConfigPluginsAP = gatsbyConfigPlugins.find(
  (o) => o.resolve === 'gatsby-plugin-accropress'
);

const gatsbyConfigPluginsAPOptions =
  gatsbyConfigPluginsAP &&
  gatsbyConfigPluginsAP.resolve === 'gatsby-plugin-accropress' &&
  gatsbyConfigPluginsAP.options
    ? gatsbyConfigPluginsAP.options
    : {};

export default function HomepagePagePage({
  pageContext: {
    integratorData,
    contentMetaData,
    contentDataData,
    navResultData,
    pluginOptions,
  },
  props,
}) {
  const config = pluginOptions;

  const [toggled, setToggled] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalData, setModalData] = useState({});

  const [noticesMainLoaded, setNoticesMainLoaded] = useState(false);
  const [noticesEmergencyLoaded, setNoticesEmergencyLoaded] = useState(false);
  const [newsfeedLoaded, setNewsfeedLoaded] = useState(false);
  const [calendarEventsLoaded, setCalendarEventsLoaded] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    var isMobileCheck = function () {
      return window.innerWidth <= 800;
    };

    console.log('isMobileCheck', isMobileCheck());

    setIsMobile(isMobileCheck());

    var video = document.getElementById('hero_video_player');

    if (video && !isMobileCheck()) {
      // get reference to video sources
      var sources = document.getElementsByClassName('video__source');

      // loop through and replace data-src with src
      for (var i = 0; i < sources.length; i++) {
        if (sources[i].getAttribute('data-src')) {
          sources[i].setAttribute('src', sources[i].getAttribute('data-src'));
          sources[i].removeAttribute('data-src'); // use only if you need to remove data-src attribute after setting src
        }
      }

      video.muted = true;

      // listen for canplay event and fade video in
      video.addEventListener('canplay', function () {
        //console.log('video duration information available');
        video.style.transition = 'opacity 2s';
        video.style.opacity = 1;
      });

      // reload video sources
      video.load();
      video.play();
    }
  }, []);

  const updateAndShowModal = (title, description, postType, attachment) => {
    setModalData({
      title: title,
      description: description,
      postType: postType,
      attachment: attachment,
    });
    setModalOpen(true);
  };

  return (
    <Layout pluginOptions={pluginOptions}>
      <SEO
        navResultData={navResultData}
        contentDataData={contentDataData}
        integratorData={integratorData}
        contentMetaData={contentMetaData}
        gatsbyConfigSiteMetaData={gatsbyConfigSiteMetaData}
        gatsbyConfigPluginsAPOptions={gatsbyConfigPluginsAPOptions}
      />
      <Modal
        classNames={{ modal: 'AccroModal' }}
        open={modalOpen && modalData ? true : false}
        onClose={() => setModalOpen(false)}
        center
      >
        {modalData && (
          <>
            <div
              dangerouslySetInnerHTML={{ __html: modalData.description }}
            ></div>
            {modalData.attachment && modalData.attachment.length > 0 && (
              <ul className="AccroModal_Attachments">
                {modalData.attachment.map((attachment) => {
                  return (
                    <li className="AccroModal_Attachments_Attachement">
                      <a
                        href={attachment.external_url}
                        title={attachment.title}
                        target="_blank"
                        rel="noopener noreferer"
                      >
                        {attachment.title}
                      </a>
                    </li>
                  );
                })}
              </ul>
            )}
            <div
              dangerouslySetInnerHTML={{
                __html: buildMediaContent(modalData.postType),
              }}
            ></div>
          </>
        )}
      </Modal>

      {contentDataData && contentDataData.length > 0 ? (
        <div className="main-page-wrapper loaded">
          {/* <button
            className="toggle btn btn-primary"
            onClick={() => setToggled(!toggled)}
          >
            Toggle Notices
          </button> */}
          <div
            className="banner home-banner"
            style={{
              backgroundImage: `linear-gradient(
                to bottom,
                rgba(0, 0, 0, 0.4),
                rgba(0, 0, 0, 0.1)
              ),
              url("${
                contentDataData[0].children[3] &&
                contentDataData[0].children[3].value
                  ? returnResizedImageURL(
                      { w: 1900 },
                      contentDataData[0].children[3].value
                    )
                  : returnResizedImageURL(
                      { w: 1900 },
                      gatsbyConfigPluginsAPOptions.siteSettings
                        .themeSettingsPayload &&
                        gatsbyConfigPluginsAPOptions.siteSettings
                          .themeSettingsPayload.length > 0 &&
                        gatsbyConfigPluginsAPOptions.siteSettings
                          .themeSettingsPayload[0] &&
                        gatsbyConfigPluginsAPOptions.siteSettings
                          .themeSettingsPayload[0].children &&
                        gatsbyConfigPluginsAPOptions.siteSettings.themeSettingsPayload[0].children.find(
                          (o) => o.id === 'home-banner-settings'
                        ) &&
                        gatsbyConfigPluginsAPOptions.siteSettings.themeSettingsPayload[0].children.find(
                          (o) => o.id === 'home-banner-settings'
                        ).columns &&
                        gatsbyConfigPluginsAPOptions.siteSettings.themeSettingsPayload[0].children.find(
                          (o) => o.id === 'home-banner-settings'
                        ).columns.value
                        ? gatsbyConfigPluginsAPOptions.siteSettings.themeSettingsPayload[0].children.find(
                            (o) => o.id === 'home-banner-settings'
                          ).columns[0].value
                        : gatsbyConfigPluginsAPOptions.siteSettings.theme.themeSettingsDefinition[0].children.find(
                            (o) => o.id === 'home-banner-settings'
                          ).columns[0].default
                    )
              }")`,
            }}
          >
            {/* {!isMobile && (
              <video
                id="hero_video_player"
                className="hero__video"
                loop
                muted
                preload="metadata"
                autoPlay
                playsInline
              >
                <source
                  className="video__source"
                  type="video/mp4"
                  data-src="https://cdn1.accropress.com/welcometokingsclere.mp4"
                />
              </video>
            )} */}
            <div className="content">
              <div className="container-float">
                <div className="container">
                  <div className="bannerWrapper">
                    <div className="bannerText">
                      <h1>{contentDataData[0].children[0].value}</h1>
                      {contentDataData[0].children[4] &&
                        contentDataData[0].children[4].value && (
                          <WigLoader
                            gatsbyConfigSiteMetaData={gatsbyConfigSiteMetaData}
                            gatsbyConfigPluginsAPOptions={
                              gatsbyConfigPluginsAPOptions
                            }
                            contentDataData={contentDataData}
                            contentMetaData={contentMetaData}
                            contentDataArray="0"
                            contentDataChild="4"
                            AccroPressProdEndpoint={
                              config.AccroPressProdEndpoint
                            }
                            AccroPressLocalEndpoint={
                              config.AccroPressLocalEndpoint
                            }
                            integratorID={config.integratorID}
                            integratorAccessToken={config.integratorAccessToken}
                          />
                        )}
                    </div>
                  </div>
                </div>
              </div>

              <div className="hero_right_side_thing">
                <div className="hero_right_side_thing_container">
                  <div className="hero_right_side_thing_item hero_right_side_thing_item_translate">
                    <div className="hero_right_side_thing_item_container">
                      <div className="hero_right_side_thing_item_icon">
                        <span class="fas fa-globe-europe"></span>
                      </div>
                      <div className="hero_right_side_thing_item_contents">
                        <Translate />
                      </div>
                    </div>
                  </div>
                  <div className="hero_right_side_thing_item hero_right_side_thing_item_Search">
                    <div className="hero_right_side_thing_item_container">
                      <div className="hero_right_side_thing_item_icon">
                        <span class="fas fa-search"></span>
                      </div>
                      <div className="hero_right_side_thing_item_contents">
                        <form action="/search/" method="get">
                          <input
                            name="term"
                            id="Search"
                            className="form-control"
                            placeholder="Enter your search here"
                          ></input>
                          <button className="btn btn-secondary" type="submit">
                            Search
                          </button>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

          

              <div
                id="weduc_notices_home_announcements_panel_wrapper"
                className={`weducWidgetDisplay_true`}
              >
                {toggled && (
                  <div id="weduc_notices_home_announcements_panel_wrapper__content">
                    <div
                      className="weduc_notices_home_announcements_panel_wrapper__content__openclose"
                      onClick={() => setToggled(false)}
                    >
                      <span className="fas fa-times"></span>
                    </div>

                    <NoticesSlickSlider
                      config={config}
                      setNoticesMainLoaded={setNoticesMainLoaded}
                      noticesMainLoaded={noticesMainLoaded}
                    />
                  </div>
                )}

                <div
                  id="weduc_notices_home_announcements_panel_wrapper__bell"
                  onClick={() => setToggled(true)}
                >
                  <div className="icon">
                    <svg
                      version="1.1"
                      x="0px"
                      y="0px"
                      width="50px"
                      height="30px"
                      viewBox="0 0 50 30"
                      enableBackground="new 0 0 50 30"
                    >
                      <g className="bell-icon__group">
                        <path
                          className="bell-icon__ball"
                          id="ball"
                          fillRule="evenodd"
                          strokeWidth="1.5"
                          clipRule="evenodd"
                          fill="none"
                          stroke="#currentColor"
                          strokeMiterlimit="10"
                          d="M28.7,25 c0,1.9-1.7,3.5-3.7,3.5s-3.7-1.6-3.7-3.5s1.7-3.5,3.7-3.5S28.7,23,28.7,25z"
                        />
                        <path
                          className="bell-icon__shell"
                          id="shell"
                          fillRule="evenodd"
                          clipRule="evenodd"
                          fill="#FFFFFF"
                          stroke="#currentColor"
                          strokeWidth="2"
                          strokeMiterlimit="10"
                          d="M35.9,21.8c-1.2-0.7-4.1-3-3.4-8.7c0.1-1,0.1-2.1,0-3.1h0c-0.3-4.1-3.9-7.2-8.1-6.9c-3.7,0.3-6.6,3.2-6.9,6.9h0 c-0.1,1-0.1,2.1,0,3.1c0.6,5.7-2.2,8-3.4,8.7c-0.4,0.2-0.6,0.6-0.6,1v1.8c0,0.2,0.2,0.4,0.4,0.4h22.2c0.2,0,0.4-0.2,0.4-0.4v-1.8 C36.5,22.4,36.3,22,35.9,21.8L35.9,21.8z"
                        />
                      </g>
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="mainContentWrapper home">
            <section className="app-content">
              <div className="container">
                <div className="row homepageWrapper">
                  <div className="col-12 col-8-m">
                    <h2>{contentDataData[1].children[0].value}</h2>
                    <WigLoader
                      gatsbyConfigSiteMetaData={gatsbyConfigSiteMetaData}
                      gatsbyConfigPluginsAPOptions={
                        gatsbyConfigPluginsAPOptions
                      }
                      contentDataData={contentDataData}
                      contentMetaData={contentMetaData}
                      contentDataArray="1"
                      contentDataChild="1"
                      AccroPressProdEndpoint={config.AccroPressProdEndpoint}
                      AccroPressLocalEndpoint={config.AccroPressLocalEndpoint}
                      integratorID={config.integratorID}
                      integratorAccessToken={config.integratorAccessToken}
                    />

                    <div className="row">
                      {contentDataData[1] &&
                        contentDataData[1].children &&
                        contentDataData[1].children[2] &&
                        contentDataData[1].children[2].children &&
                        contentDataData[1].children[2].children.map(
                          (column, i) => (
                            <div className="col-12 col-6-m" key={i}>
                              <Link
                                to={column[3].value}
                                title={column[3].value}
                                className="quickLinkTile"
                                style={{
                                  backgroundImage: `url(${
                                    column[0].value
                                      ? returnResizedImageURL(
                                          { w: 385 },
                                          column[0].value
                                        )
                                      : ''
                                  })`,
                                }}
                              >
                                <div className="wrap">
                                  <div className="quickLinkText">
                                    <span>{column[1].value}</span>
                                    <span
                                      className={'fas ' + column[2].value}
                                    ></span>
                                  </div>
                                </div>
                              </Link>
                            </div>
                          )
                        )}
                    </div>
                  </div>

                  <div className="col-12 col-4-m">
                    <div
                      className={
                        'sidebarMenuItem weducWidgetDisplay_' +
                        calendarEventsLoaded
                      }
                    >
                      <div className="header">
                        <h3>Upcoming Events</h3>
                        <Link to="/events">View all</Link>
                      </div>
                      <WeducDairyDates
                        weducAPIURL="https://weduc.accropress.com/smartfeed/"
                        limit={5}
                        gatsbyConfigSiteMetaData={gatsbyConfigSiteMetaData}
                        gatsbyConfigPluginsAPOptions={
                          gatsbyConfigPluginsAPOptions
                        }
                        loadedEvent={setCalendarEventsLoaded}
                        name={config.weducWebsitesID}
                        container={config.weducContainerID}
                        html={function CalendarTemplate(props) {
                          return (
                            <div
                              onClick={() =>
                                updateAndShowModal(
                                  props.data.title,
                                  props.data.description
                                )
                              }
                              className="sidebarDataItem"
                            >
                              <span className="far fa-calendar"></span>
                              <div>
                                {props.data.title}
                                <span>
                                  {moment(props.data.start_date).format('LL')}
                                </span>
                              </div>
                            </div>
                          );
                        }}
                        route={'/events'}
                      />
                    </div>
                    <div className="sidebarMenuItem">
                      <div className="header">
                        <h3>Value of the Month</h3>
                      </div>
                      <Link className="sidebarValue" to="/">
                        <span className="valueOfTheMonth_title title">
                          {contentDataData[2].children[1].title}
                        </span>
                        <p className="valueOfTheMonth_content content">
                          {contentDataData[2].children[1].content}
                        </p>
                        {contentDataData[2].children[1].author &&
                          contentDataData[2].children[1].author !== '' && (
                            <span className="valueOfTheMonth_author author">
                              {contentDataData[2].children[1].author}
                            </span>
                          )}
                      </Link>
                    </div>
                    <div
                      className={
                        'sidebarMenuItem weducWidgetDisplay_' + newsfeedLoaded
                      }
                    >
                      <div className="header">
                        <h3>Latest News</h3>
                        <Link to="/latest-news">View all</Link>
                      </div>
                      <WeducNewsFeed
                        loadedEvent={setNewsfeedLoaded}
                        limit={5}
                        gatsbyConfigSiteMetaData={gatsbyConfigSiteMetaData}
                        gatsbyConfigPluginsAPOptions={
                          gatsbyConfigPluginsAPOptions
                        }
                        name={config.weducWebsitesID}
                        container={config.weducContainerID}
                        html={function NewsFeedTemplate(props) {
                          return (
                            <div
                              onClick={() =>
                                updateAndShowModal(
                                  props.data.title,
                                  props.data.contentHtml,
                                  props.data.postType
                                )
                              }
                              className="sidebarDataItem"
                            >
                              <span className="far fa-newspaper"></span>
                              <div>
                                {props.data.contentHtml
                                  .replace(/<\/p>]*>?/gm, ' ')
                                  .replace(/<[^>]*>?/gm, '')
                                  .substring(0, 30)}
                                {props.data.contentHtml
                                  .replace(/<\/p>]*>?/gm, ' ')
                                  .replace(/<[^>]*>?/gm, '').length > 30
                                  ? '...'
                                  : ''}
                                <span>{props.data.createdAt}</span>
                              </div>
                            </div>
                          );
                        }}
                        route={'/latest-news'}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      ) : (
        <div className="main-page-wrapper loading"></div>
      )}
    </Layout>
  );
}
